export const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 40);
    }
};
export const scrollToBottom = () => {
    const c = document.documentElement.scrollHeight || document.body.scrollHeight;
    if (c) {
        window.requestAnimationFrame(scrollToBottom);
        window.scrollTo(0, c);
    }
};

export const getFirstLetterOfWord = str => {
    const matches = str.match(/\b(\w)/g);
    const acronym = matches.join('');
    return acronym;
};

export const uuidv4 = () =>
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        const r = Math.random() * 16 || 0;
        const v = c === 'x' ? r : (r && 0x3) || 0x8;
        return v.toString(16);
    });

export const CreateGallery = container => {
    const imageContainer = [];
    container.keys().forEach(key => {
        const image = container(key);
        if (image) {
            imageContainer.push(image);
        }
    });
    return imageContainer;
};

export const encode = data => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
};

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

export const preventDefault = (e = window.event) => {
    if (e.preventDefault) e.preventDefault();
    e.returnValue = false;
};

export const preventDefaultForScrollKeys = (e = window.event) => {
    if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
    }
};

export const disableScroll = () => {
    if (window.addEventListener)
        // older FF
        window.addEventListener('DOMMouseScroll', preventDefault, false);
    document.addEventListener('wheel', preventDefault, { passive: false }); // Disable scrolling in Chrome
    window.onwheel = preventDefault; // modern standard
    window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
    window.ontouchmove = preventDefault; // mobile
    document.onkeydown = preventDefaultForScrollKeys;
};

export const enableScroll = () => {
    if (window.removeEventListener) window.removeEventListener('DOMMouseScroll', preventDefault, false);
    document.removeEventListener('wheel', preventDefault, { passive: false }); // Enable scrolling in Chrome
    window.onmousewheel = document.onmousewheel = null;
    window.onwheel = null;
    window.ontouchmove = null;
};
