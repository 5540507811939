import React from 'react';
import styled from 'styled-components';

import { respondAbove } from '../Styling/Queries';
import { outlineFillTheme } from '../Styling/Theme';

export default ({ rightAlign, children }) => {
    return <SecondaryTitle rightAlign={rightAlign} dangerouslySetInnerHTML={{ __html: children }} />;
};

const SecondaryTitle = styled.div`
    align-self: ${props => (props.rightAlign ? 'flex-end' : 'flex-start')};
    font-size: 3rem;
    font-weight: 700;
    pointer-events: none;
    text-align: ${props => (props.rightAlign ? 'right' : 'left')};
    text-transform: uppercase;
    white-space: pre-wrap;
    width: 100%;
        ${outlineFillTheme}
    @media ${respondAbove.md} {
        max-width: 1600px;
        margin: 0 auto;
        margin: 0 0 2rem 0rem;
        line-height: 12rem;
        font-size: 8.5rem;
    }
`;
