import React from 'react';
import styled from 'styled-components';
// import { respondBelow, respondAbove } from '../Styling/Queries';

export default ({ reverse, children, props }) => (
    <SpaceXL {...props} reverse={reverse}>
        {children}
    </SpaceXL>
);

const SpaceXL = styled.div`
    flex: 1;
`;
