import React, { useContext, forwardRef } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import styled from 'styled-components';
import { RightAlignContext } from '../../Context';
import PageTitle from './PageTitle';
import Row from './Row';
import SpaceXL from './SpaceXL';
import { respondAbove } from '../Styling/Queries';

export default forwardRef(({ pageClass, title, children, id }, ref) => {
    const rightAlign = useContext(RightAlignContext);

    return (
        <AppPages ref={ref} id={id} className={`app-pages ${pageClass || ''}`}>
            {title && (
                <Controller>
                    <Scene duration={800} triggerHook='onEnter'>
                        <Tween from={{ opacity: 0, x: rightAlign ? '100%' : '-100%' }}>
                            <div>
                                <SpaceHeightMedium />
                                <Row reverse={rightAlign}>
                                    <SpaceXL />
                                    <PageTitle title={title} />
                                </Row>
                                <SpaceHeightMedium />
                            </div>
                        </Tween>
                    </Scene>
                </Controller>
            )}
            <AppPagesContainer>{children}</AppPagesContainer>
        </AppPages>
    );
});

export const SpaceHeightBig = styled.div`
    height: 10rem;
`;
export const SpaceHeightMedium = styled.div`
    height: 5rem;
`;
export const SpaceHeightSmall = styled.div`
    height: 2.5rem;
`;
const AppPagesContainer = styled.div`
    height: 100%;
    position: relative;
    width: 100%;
`;
const AppPages = styled.div`
    align-items: center;
    background: $bgColor;
    color: white;
    font-size: calc(10px + 2vmin);
    justify-content: center;
    padding: 0 2rem;
    position: relative;
    transform: all 0.2s ease;
    width: 100vw;

    @media ${respondAbove.sm} {
        min-height: 60vh;
    }
    &.intro {
        height: 100vh;
        padding-top: 0;
        position: relative;
        @media ${respondAbove.sm} {
            height: 100vh;
        }
    }
`;
