import React from 'react';
import '../../scss/Job-modal.scss';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import styled from 'styled-components';
import CloseIcon from '../components/CloseIcon';
import { uuidv4 } from '../utils';
import InfoWrapper from './components/InfoWrapper';
import { respond } from '../Styling/Queries';
import { STRONG_ID, KNOWLEDGEABLE_ID } from '../Data/VariablesData';
import JobTool from './components/JobTool';
import SecondaryTitle from '../components/SecondaryTitle';
import { backgroundColor, textColor, borderColor } from '../Styling/Theme';

export const Transition = React.forwardRef((props, ref) => (
    <Slide direction='left' {...props} ref={ref} mountOnEnter unmountOnExit timeout={{ enter: 800, exit: 800 }} />
));
export const TransitionUp = React.forwardRef((props, ref) => (
    <Slide direction='up' {...props} ref={ref} mountOnEnter unmountOnExit timeout={{ enter: 800, exit: 800 }} />
));

const DialogWrapped = styled(Dialog)`
    .MuiPaper-root {
        background-color: ${backgroundColor};
    }
`;

export default ({
    open,
    closeAction,
    name,
    data: { role, dates: { start, end, startyear, endyear } = {}, description = [], Skills = [] } = {},
}) => {
    const Strong = Skills.filter(item => item.levelId === STRONG_ID);
    const Knowledgeable = Skills.filter(item => item.levelId === KNOWLEDGEABLE_ID);
    return (
        <DialogWrapped
            fullScreen
            background={backgroundColor}
            open={open}
            onClose={closeAction}
            TransitionComponent={Transition}>
            <div className='job-modal'>
                <CloseIcon action={closeAction} />
                <div className='job'>
                    <SecondaryTitle>About</SecondaryTitle>
                    <Row>
                        <Col>
                            <InfoWrapper label='role' data={role} />
                            <InfoWrapper label='company' data={name} />
                            <InfoWrapper label='start' data={start + (startyear && ' ' + startyear)} />
                            <InfoWrapper label='end' data={end + (endyear && ' ' + endyear)} />
                        </Col>
                        <JobDescription>
                            {description.map(desc => (
                                <Achievement key={uuidv4()} dangerouslySetInnerHTML={{ __html: desc }} />
                            ))}
                        </JobDescription>
                    </Row>
                </div>
                <SecondaryTitle rightAlign>Tech Stack</SecondaryTitle>
                <JobTool data={Strong} title='strong' />
                <JobTool data={Knowledgeable} title='Knowledgeable' />
            </div>
        </DialogWrapped>
    );
};

const Col = styled.div`
    margin-bottom: 4rem;
    @media screen and ${respond.above.md} {
        flex: 1;
    }
`;
const Row = styled.div`
    @media screen and ${respond.above.md} {
        display: flex;
    }
`;
const JobDescription = styled.div`
    color: ${textColor};
    flex: 2;
    font-size: 1rem;
    font-weight: 400;
    margin: 0 0 1rem;
    text-align: left;
    @media screen and ${respond.above.md} {
        min-height: 45vh;
    }
`;
const Achievement = styled.div`
    border-left: 2px solid ${borderColor};
    color: white;
    font-size: 1rem;
    font-weight: bold;
    margin: 0 auto 30px;
    padding-left: 20px;
    @media screen and ${respond.above.md} {
        font-size: 1.3rem;
        padding-bottom: 20px;
        margin: 0 60px 30px 0;
    }
`;
