import React from 'react';
import { connect } from 'react-redux';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import styled from 'styled-components';

import AppPages from '../components/AppPages';
import { Education } from '../Data';
import { respondAbove } from '../Styling/Queries';
import EducationWrapper from '../components/EducationWrapper';

const EducationSection = ({ isMobile }) => {
    return (
        <AppPages pageClass='edu' title="Education">
            <Controller>
                <Scene duration={400} offset={-500}>
                    <Tween from={{ x: isMobile ? '-100%' : '100%' }}>
                        <EducationContainer>
                            <div />
                            {Education.map(props => (
                                <EducationWrapper isMobile={isMobile} key={props.name} {...props} />
                            ))}
                        </EducationContainer>
                    </Tween>
                </Scene>
            </Controller>
        </AppPages>
    );
};

const EducationContainer = styled.div`
    justify-content: space-between;
    padding-left: 4rem;
    width: 100%;

    @media ${respondAbove.md} {
        display: flex;
        padding-left: 0rem;
    }
`;
const mapStateToProps = ({ isMobile }) => ({ isMobile });

export default connect(mapStateToProps)(EducationSection);
