import React from 'react';
import styled from 'styled-components';
import { respondAbove } from '../../Styling/Queries';
import { infoLabelColor } from '../../Styling/Theme';

export default ({ children }) => <InfoLabel>{children}</InfoLabel>;

const InfoLabel = styled.div`
    color: ${infoLabelColor};
    font-size: 1.1rem;
    font-weight: normal;
    text-transform: uppercase;
    @media screen and ${respondAbove.md} {
        font-size: 1.5rem;
    }
`;
