import React from 'react';

export default WrappedComponent => {
    class WithRef extends React.Component {
        render() {
            const { forwardedRef, ...props } = this.props;

            return <WrappedComponent ref={forwardedRef} {...props} />;
        }
    }
    return React.forwardRef((props, ref) => {
        return <WithRef {...props} forwardedRef={ref} />;
    });
};
