import React from 'react';
import styled from 'styled-components';
// import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
// import Resume from '../pdf/filip/Resume';
import Introduction from './sections/Introduction';
import WorkExperience from './sections/WorkExperience';
import Skills from './sections/Skills';
// import Projects from './sections/Projects';
import Education from './sections/Education';
import { RightAlignProvider } from '../Context';
import { backgroundColor } from './Styling/Theme';
import { useTheme } from '../ThemeContext';
import withRef from './withRef';

const AppContainer = () => {
    const { mode } = useTheme();

    return (
        <Container>
            {/* <RightAlignProvider>
                <PDFViewer width='100%' height='1200px'>
                    <Resume language='en' />
                </PDFViewer>

                <PDFDownloadLink document={<Resume />} fileName='filip_pachucki_cv.pdf'>
                    Resume <sup>Download</sup>
                </PDFDownloadLink>
            </RightAlignProvider> */}
            <RightAlignProvider>
                <Introduction mode={mode} />
            </RightAlignProvider>
            <RightAlignProvider value>
                <WorkExperience />
            </RightAlignProvider>
            <RightAlignProvider>{/* <Projects /> */}</RightAlignProvider>
            <RightAlignProvider>
                <Education />
            </RightAlignProvider>
            <RightAlignProvider value>
                <Skills />
            </RightAlignProvider>
        </Container>
    );
};
export default withRef(AppContainer);

const Container = styled.div`
    background-color: ${backgroundColor};
    overflow-x: hidden;
    transition: background-color 1s;
`;
