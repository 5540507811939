import React from 'react';
import styled from 'styled-components';
import { respondBelow, respondAbove } from '../Styling/Queries';
import { rightandLeft } from '../Styling/Keyframes';
import Arrow from './Arrow';
import { outlineTheme, outlineThemeHover, outlineThemeActive, fillThemeHover, fillTheme } from '../Styling/Theme';

export default ({ children, outline, active, arrow, ...props }) => (
    <StyledH1 outline={outline} className={active ? 'active' : ''} {...props}>
        {children}
        {arrow && <Arrow />}
    </StyledH1>
);

const StyledH1 = styled.h1`
    color: ${fillTheme};
    cursor: pointer;
    font-size: 1.5rem;
    letter-spacing: 3px;
    text-transform: uppercase;
    @media ${respondBelow.md} {
        white-space: pre-wrap;
    }
    @media ${respondAbove.md} {
        font-size: 3rem;
    }

    ${({ outline }) => outline && outlineTheme}

    &.active {
        color: ${({ outline }) => outline && outlineThemeActive};
        font-weight: 700;
        pointer-events: none;
        text-shadow: none;
        &:hover {
            color: ${({ outline }) => outline && outlineThemeActive};
        }
    }

    &:hover {
        color: ${({ outline }) => (outline ? outlineThemeHover : fillThemeHover)};
        text-shadow: none;
        .arrow {
            animation: ${rightandLeft()} 500ms ease alternate infinite;
        }
    }
`;
