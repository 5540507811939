import React from 'react';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import SkillCategoryLevels from './SkillCategoryLevels';
import { STRONG_ID, KNOWLEDGEABLE_ID } from '../Data/VariablesData';
import CustomH1 from './CustomH1';
import Row from './Row';
import { complementColor } from '../Styling/Theme';

export default ({ id, skill, selectedCategory, showCategory }) => {
    return (
        <ExpansionPanelWrapped
            expanded={id === selectedCategory}
            onClick={() => showCategory(id === selectedCategory ? '' : id)}>
            <ExpansionPanelSummaryWrapped
                expandIcon={<ExpandMoreIconWhite />}
                aria-controls='panel1bh-content'
                id='panel1bh-header'>
                <CustomH1 key={skill} active={id === selectedCategory}>
                    {skill}
                </CustomH1>
            </ExpansionPanelSummaryWrapped>
            <ExpansionPanelDetailsWrapped>
                <Row>
                    <SkillCategoryLevels
                        turnOffScrollAnimation
                        levelId={STRONG_ID}
                        selectedCategory={selectedCategory}
                    />
                    <SkillCategoryLevels
                        turnOffScrollAnimation
                        levelId={KNOWLEDGEABLE_ID}
                        selectedCategory={selectedCategory}
                    />
                </Row>
            </ExpansionPanelDetailsWrapped>
        </ExpansionPanelWrapped>
    );
};

const ExpansionPanelWrapped = withStyles({
    root: {
        background: 'transparent',
        borderRadius: ' 0 !important',
        padding: '10px',
        '&:last-child': {
            borderBottom: `1px solid ${complementColor}`,
        },
        boxShadow: 'none',
        '&:hover': {
            background: 'transparent',
        },
    },
})(ExpansionPanel);

const ExpansionPanelSummaryWrapped = withStyles({
    root: {
        padding: '0px',
    },
})(ExpansionPanelSummary);

const ExpansionPanelDetailsWrapped = withStyles({
    root: {
        padding: 0,
    },
})(ExpansionPanelDetails);

const ExpandMoreIconWhite = withStyles({
    root: {
        fill: 'rgb(235, 199, 78)',
    },
})(ExpandMoreIcon);
