import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';

import { skillCategory } from '../Data';

import Accordion from './Accordion';
import { SkillCategoryStyled } from './SkillCategory';

export default ({ selectedCategory, showCategory }) => {
    return (
        <SkillCategoryStyled>
            <Controller>
                <Scene duration={800} offset={-900}>
                    <Tween
                        staggerFrom={{
                            opacity: 0,
                            cycle: {
                                x: i => `-${100 + i * 10}%`,
                            },
                        }}
                        stagger={0.1}>
                        {skillCategory.map(({ id, category }) => {
                            return (
                                <div key={category}>
                                    <Accordion
                                        id={id}
                                        skill={category}
                                        selectedCategory={selectedCategory}
                                        showCategory={showCategory}
                                    />
                                </div>
                            );
                        })}
                    </Tween>
                </Scene>
            </Controller>
        </SkillCategoryStyled>
    );
};
