import { css } from 'styled-components';
import theme from 'styled-theming';
import { darken, lighten } from 'polished';
import { COLORS } from '../Data/VariablesData';
import { outline } from './Mixins';

export const backgroundColor = theme('mode', {
    violet: COLORS.VIOLET,
    purple: COLORS.PURPLE,
    green: COLORS.GREEN,
    dark: COLORS.DARK,
    blue: COLORS.BLUE,
    red: COLORS.RED,
    mcd: COLORS.MCD,
});
export const textColor = theme('mode', {
    blue: COLORS.BLUE_TEXT,
    red: COLORS.RED_TEXT,
    violet: COLORS.VIOLET_TEXT,
    purple: COLORS.PURPLE_TEXT,
    green: COLORS.GREEN_TEXT,
    dark: COLORS.WHITE,
    mcd: COLORS.MCD_TEXT,
});
export const infoLabelColor = theme('mode', {
    blue: COLORS.BLUE_TEXT,
    red: COLORS.RED_TEXT,
    violet: COLORS.VIOLET_TEXT,
    purple: COLORS.PURPLE_TEXT,
    green: COLORS.GREEN_TEXT,
    dark: COLORS.DARK_COMPLEMENTARY,
    mcd: COLORS.MCD_TEXT,
});
export const menuItemsColor = theme('mode', {
    blue: COLORS.BLUE_TEXT,
    red: COLORS.RED_TEXT,
    violet: COLORS.VIOLET_TEXT,
    purple: COLORS.PURPLE_TEXT,
    green: COLORS.GREEN_TEXT,
    dark: COLORS.WHITE,
    mcd: COLORS.MCD_COMPLEMENTARY,
});
export const menuItemsColorHover = theme('mode', {
    blue: COLORS.BLUE_COMPLEMENTARY,
    red: COLORS.RED_TEXT,
    violet: COLORS.VIOLET_COMPLEMENTARY,
    purple: COLORS.PURPLE_COMPLEMENTARY,
    green: COLORS.GREEN_COMPLEMENTARY,
    dark: COLORS.DARK_COMPLEMENTARY_2,
    mcd: COLORS.MCD_TEXT,
});
export const placeholderColor = theme('mode', {
    blue: COLORS.BLUE_COMPLEMENTARY,
    violet: COLORS.VIOLET_TEXT,
    purple: COLORS.PURPLE_COMPLEMENTARY,
    green: COLORS.GREEN_COMPLEMENTARY,
    red: COLORS.RED_COMPLEMENTARY,
    dark: COLORS.GOLD,
    mcd: COLORS.MCD_COMPLEMENTARY,
});
export const complementColor = theme('mode', {
    blue: COLORS.BLUE_COMPLEMENTARY,
    violet: COLORS.VIOLET_COMPLEMENTARY,
    purple: COLORS.PURPLE_COMPLEMENTARY,
    green: COLORS.GREEN_COMPLEMENTARY,
    red: COLORS.RED_COMPLEMENTARY,
    dark: COLORS.DARK_COMPLEMENTARY,
    mcd: COLORS.MCD_COMPLEMENTARY,
});
export const arrowColor = theme('mode', {
    blue: COLORS.BLUE_COMPLEMENTARY,
    violet: COLORS.VIOLET_COMPLEMENTARY,
    purple: COLORS.PURPLE_COMPLEMENTARY,
    green: COLORS.GREEN_TEXT,
    red: COLORS.RED_COMPLEMENTARY,
    dark: '#ebc74e',
    mcd: COLORS.MCD_TEXT,
});
export const customH1ColorHover = theme('mode', {
    blue: COLORS.BLUE_TEXT,
    violet: COLORS.VIOLET_COMPLEMENTARY,
    purple: COLORS.PURPLE_COMPLEMENTARY,
    green: COLORS.GREEN_COMPLEMENTARY,
    red: COLORS.RED_COMPLEMENTARY,
    dark: COLORS.DARK_COMPLEMENTARY,
    mcd: COLORS.MCD_TEXT,
});
export const customH1Color = theme('mode', {
    blue: COLORS.BLUE_COMPLEMENTARY,
    red: COLORS.RED_TEXT,
    violet: COLORS.VIOLET_TEXT,
    purple: COLORS.PURPLE_TEXT,
    green: COLORS.GREEN_COMPLEMENTARY,
    dark: COLORS.DARK_TEXT,
    mcd: COLORS.MCD_TEXT,
});
export const educationTitleColor = theme('mode', {
    blue: COLORS.BLUE_TEXT,
    violet: COLORS.VIOLET_COMPLEMENTARY,
    purple: COLORS.PURPLE_TEXT,
    green: COLORS.GREEN_COMPLEMENTARY,
    red: COLORS.RED_COMPLEMENTARY,
    dark: COLORS.DARK_COMPLEMENTARY_2,
    mcd: COLORS.MCD_TEXT,
});
export const skillsLevelColor = theme('mode', {
    blue: COLORS.BLUE_COMPLEMENTARY,
    violet: COLORS.VIOLET_COMPLEMENTARY,
    purple: COLORS.PURPLE_TEXT,
    green: COLORS.GREEN_TEXT,
    red: COLORS.RED_TEXT,
    dark: COLORS.DARK_COMPLEMENTARY_2,
    mcd: COLORS.MCD_TEXT,
});
export const contactColor = theme('mode', {
    blue: COLORS.BLUE_TEXT,
    violet: COLORS.VIOLET_TEXT,
    purple: COLORS.PURPLE_TEXT,
    green: COLORS.GREEN_TEXT,
    red: COLORS.RED_COMPLEMENTARY,
    dark: COLORS.DARK_TEXT,
    mcd: COLORS.MCD_COMPLEMENTARY,
});
export const contactHoverColor = theme('mode', {
    blue: COLORS.BLUE_COMPLEMENTARY,
    violet: COLORS.VIOLET_COMPLEMENTARY,
    purple: COLORS.PURPLE_TEXT,
    green: COLORS.GREEN_COMPLEMENTARY,
    red: COLORS.RED_TEXT,
    dark: COLORS.DARK_COMPLEMENTARY_2,
    mcd: COLORS.MCD_TEXT,
});
export const hoverColor = theme('mode', {
    blue: COLORS.BLUE_COMPLEMENTARY,
    violet: COLORS.VIOLET_COMPLEMENTARY,
    purple: COLORS.PURPLE_COMPLEMENTARY,
    green: COLORS.GREEN_COMPLEMENTARY,
    red: COLORS.RED_COMPLEMENTARY,
    dark: COLORS.DARK_COMPLEMENTARY_2,
    mcd: COLORS.MCD_TEXT,
});

// GRADIENT BACKGROUND TITLE
export const gradientBackground = theme('mode', {
    blue: css``,
    purple: css``,
    violet: css``,
    green: css`
        background: linear-gradient(red, transparent), linear-gradient(to top left, lime, transparent),
            linear-gradient(to top right, blue, transparent);
        background-blend-mode: screen;
    `,
    red: css``,
    mcd: css``,
    dark: css``,
});
// PAGE TITLE
export const outlineTitleTheme = theme('mode', {
    blue: css`
        ${outline(COLORS.BLUE_COMPLEMENTARY, COLORS.BLUE)};
    `,
    purple: css`
        ${outline(COLORS.PURPLE_COMPLEMENTARY, COLORS.PURPLE)};
    `,
    violet: css`
        ${outline(COLORS.VIOLET_COMPLEMENTARY, COLORS.VIOLET)};
    `,
    green: css`
        ${outline('transparent', COLORS.GREEN_COMPLEMENTARY)};
    `,
    red: css`
        ${outline(COLORS.RED_COMPLEMENTARY, COLORS.RED)};
    `,
    mcd: css`
        ${outline('transparent', COLORS.MCD_COMPLEMENTARY)};
    `,
    dark: css`
        ${outline(COLORS.DARK_COMPLEMENTARY, COLORS.DARK)};
    `,
});

export const outlineTheme = theme('mode', {
    blue: css`
        ${outline(COLORS.BLUE_COMPLEMENTARY, COLORS.BLUE)};
    `,
    purple: css`
        ${outline(COLORS.PURPLE_COMPLEMENTARY, COLORS.PURPLE)};
    `,
    violet: css`
        ${outline(COLORS.VIOLET_COMPLEMENTARY, COLORS.VIOLET)};
    `,
    green: css`
        ${outline(COLORS.GREEN_COMPLEMENTARY, COLORS.GREEN)};
    `,
    red: css`
        ${outline(COLORS.RED_COMPLEMENTARY, COLORS.RED)};
    `,
    mcd: css`
        ${outline(COLORS.MCD_COMPLEMENTARY, COLORS.MCD)};
    `,
    dark: css`
        ${outline(COLORS.DARK_COMPLEMENTARY_3, COLORS.DARK)};
    `,
});
export const outlineThemeHover = theme('mode', {
    blue: COLORS.BLUE_COMPLEMENTARY,
    purple: COLORS.PURPLE_COMPLEMENTARY,
    violet: COLORS.VIOLET_COMPLEMENTARY,
    green: COLORS.GREEN_COMPLEMENTARY,
    red: COLORS.RED_COMPLEMENTARY,
    mcd: COLORS.MCD_COMPLEMENTARY,
    dark: COLORS.DARK_COMPLEMENTARY,
});
export const outlineThemeActive = theme('mode', {
    blue: COLORS.BLUE_TEXT,
    purple: COLORS.PURPLE_TEXT,
    violet: COLORS.VIOLET_TEXT,
    green: COLORS.GREEN_TEXT,
    red: COLORS.RED_COMPLEMENTARY,
    mcd: COLORS.MCD_TEXT,
    dark: COLORS.DARK_TEXT,
});

export const fillTheme = theme('mode', {
    blue: COLORS.BLUE_COMPLEMENTARY,
    purple: COLORS.PURPLE_TEXT,
    violet: COLORS.VIOLET_COMPLEMENTARY,
    green: COLORS.GREEN_COMPLEMENTARY,
    red: COLORS.RED_COMPLEMENTARY,
    mcd: COLORS.MCD_COMPLEMENTARY,
    dark: COLORS.DARK_TEXT,
});
export const fillThemeHover = theme('mode', {
    blue: COLORS.BLUE_TEXT,
    purple: COLORS.PURPLE_COMPLEMENTARY,
    violet: COLORS.VIOLET_TEXT,
    green: COLORS.GREEN_TEXT,
    red: COLORS.RED_COMPLEMENTARY,
    mcd: COLORS.MCD_TEXT,
    dark: COLORS.DARK_COMPLEMENTARY_2,
});

export const outlineHeaderTheme = theme('mode', {
    blue: css`
        ${outline(COLORS.BLUE_TEXT, COLORS.BLUE)};
    `,
    purple: css`
        ${outline(COLORS.PURPLE_TEXT, COLORS.PURPLE)};
    `,
    violet: css`
        ${outline(COLORS.VIOLET_TEXT, COLORS.VIOLET)};
    `,
    green: css`
        ${outline(COLORS.GREEN_TEXT, COLORS.GREEN)};
    `,
    red: css`
        ${outline(COLORS.RED_TEXT, COLORS.RED)};
    `,
    mcd: css`
        ${outline(COLORS.MCD_TEXT, COLORS.MCD)};
    `,
    dark: css`
        ${outline()};
    `,
});

export const renderSwitch = (param) => {
    switch (param) {
        case 'blue':
            return COLORS.BLUE_TEXT;
        case 'green':
            return COLORS.GREEN_TEXT;
        case 'violet':
            return COLORS.VIOLET_TEXT;
        case 'purple':
            return COLORS.PURPLE_TEXT;
        case 'dark':
            return COLORS.YELLOW;
        case 'red':
            return COLORS.RED_TEXT;
        case 'mcd':
            return COLORS.MCD_TEXT;
        default:
            return COLORS.YELLOW;
    }
};
export const outlineFillTheme = theme('mode', {
    blue: css`
        ${outline('transparent', COLORS.BLUE_COMPLEMENTARY)};
    `,
    purple: css`
        ${outline('transparent', COLORS.PURPLE_COMPLEMENTARY)};
    `,
    violet: css`
        ${outline('transparent', COLORS.VIOLET_COMPLEMENTARY)};
    `,
    green: css`
        ${outline('transparent', COLORS.GREEN_COMPLEMENTARY)};
    `,
    red: css`
        ${outline('transparent', COLORS.RED_COMPLEMENTARY)};
    `,
    mcd: css`
        ${outline('transparent', COLORS.MCD_COMPLEMENTARY)};
    `,
    dark: css`
        ${outline()};
    `,
});
export const buttonColor = theme('mode', {
    blue: COLORS.BLUE_COMPLEMENTARY,
    purple: COLORS.PURPLE_COMPLEMENTARY,
    violet: COLORS.VIOLET_COMPLEMENTARY,
    green: COLORS.GREEN_COMPLEMENTARY,
    red: COLORS.RED_COMPLEMENTARY,
    dark: COLORS.DARK_COMPLEMENTARY,
    mcd: COLORS.MCD_TEXT,
});

export const borderColor = theme('mode', {
    blue: COLORS.BLUE_TEXT,
    violet: COLORS.VIOLET_COMPLEMENTARY,
    purple: COLORS.PURPLE_TEXT,
    green: COLORS.GREEN_TEXT,
    red: COLORS.RED_TEXT,
    mcd: COLORS.MCD_TEXT,
    dark: COLORS.DARK_COMPLEMENTARY,
});

export const InputBorderColor = theme('mode', {
    blue: `${lighten(0.1, COLORS.BLUE)}`,
    violet: `${lighten(0.1, COLORS.VIOLET)}`,
    purple: `${lighten(0.1, COLORS.PURPLE)}`,
    green: `${lighten(0.1, COLORS.GREEN)}`,
    red: `${lighten(0.1, COLORS.RED)}`,
    mcd: `${lighten(0.1, COLORS.MCD)}`,
    dark: '#2f2f2f',
});

export const inputBackgroundColor = theme('mode', {
    blue: css`
        background-color: ${darken(0.03, COLORS.BLUE)};
    `,
    purple: css`
        background-color: ${darken(0.03, COLORS.PURPLE)};
    `,
    violet: css`
        background-color: ${darken(0.03, COLORS.VIOLET)};
    `,
    green: css`
        background-color: ${darken(0.03, COLORS.GREEN)};
    `,
    red: css`
        background-color: ${darken(0.03, COLORS.RED)};
    `,
    mcd: css`
        background-color: ${darken(0.03, COLORS.MCD)};
    `,
    dark: css`
        background-color: ${darken(0.013, COLORS.DARK)};
    `,
});
