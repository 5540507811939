import React from 'react';
import InfoLabel from './InfoLabel';
import Info from './Info';

export default ({ label, data, dangerously }) => (
    <div key={data} className='info'>
        <InfoLabel>{label}</InfoLabel>
        {dangerously ? <Info dangerouslySetInnerHTML={{ __html: data }} /> : <Info>{data}</Info>}
    </div>
);
