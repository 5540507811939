import { keyframes } from 'styled-components';

export const rightandLeft = (number = 2) => keyframes`
    0% {
      transform: translateX(0)  scale(${number});
  }

  100% {
      transform: translateX(29%) scale(${number});
  }
`;

export const sliderArrowNext = keyframes`
  0% {
      transform: translateX(0);
  }

  50% {
      transform: translateX(-29%);
  }

  100% {
      transform: translateX(0%);
  }
`;

export const sliderArrowNext2 = keyframes`
  0% {
      transform: translateX(0);
  }

  50% {
      transform: translateX(29%);
  }

  100% {
      transform: translateX(0%);
  }
`;
export const fade = keyframes`
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
`;
export const slideUp = keyframes`
  0% {
      transform: translate3d(0px, 100%, 0px);
  }

  100% {
      position: fixed;
  }
`;

export const upanddown = keyframes`
  0% {
      position: fixed;
      transform: translateY(0);
  }

  50% {
      position: fixed;
      transform: translateY(-29%);
  }

  100% {
      position: fixed;
      transform: translateY(0%);
  }
`;

export const downandup = keyframes`
  100% {
      transform: translateY(30%);
    }

`;
