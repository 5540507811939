import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';

import { roles } from '../Data';
import CustomH1 from './CustomH1';

export default ({ jobrole, handleSelectRole }) => (
    <div className='job-roles'>
        <Controller>
            {roles.map(({ id, title }) => (
                <Scene duration={600} offset={-750} key={title}>
                    <Tween
                        staggerFrom={{
                            opacity: 0,
                            cycle: {
                                x: i => `-${100 + i * 10}%`,
                            },
                        }}
                        stagger={0.2}>
                        <div>
                            <CustomH1
                                outline
                                id={`${id}`}
                                onClick={() => handleSelectRole(id)}
                                onKeyPress={e => e.key === 'Enter' && handleSelectRole(id)}
                                role='presentation'
                                active={jobrole === id}>
                                {title}
                            </CustomH1>
                        </div>
                    </Tween>
                </Scene>
            ))}
        </Controller>
    </div>
);
