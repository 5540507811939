import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import styled from 'styled-components';

import { Tween } from 'react-gsap';
import { skillCategory } from '../Data';
import CustomH1 from './CustomH1';
import { respondAbove } from '../Styling/Queries';

export default ({ selectedCategory, showCategory }) => (
    <SkillCategoryStyled>
        <Controller>
            <Scene duration={500} offset={-700}>
                <Tween
                    staggerFrom={{
                        opacity: 0,
                        cycle: {
                            x: i => `-${100 + i * 10}%`,
                        },
                    }}
                    stagger={0.2}>
                    {skillCategory.map(({ category, id }) => {
                        return (
                            <div
                                key={category}
                                role='button'
                                tabIndex={0}
                                onClick={() => showCategory(id)}
                                onKeyPress={({ key }) => key === 'Enter ' && showCategory(id)}>
                                <CustomH1 outline active={id === selectedCategory}>
                                    {category}
                                </CustomH1>
                            </div>
                        );
                    })}
                </Tween>
            </Scene>
        </Controller>
    </SkillCategoryStyled>
);
export const SkillCategoryStyled = styled.div`
    cursor: pointer;
    margin-bottom: 5rem;
    @media and ${respondAbove.md} {
        padding-right: 2rem;
    }
`;
