import React from 'react';
import styled from 'styled-components';
import { respondBelow, respondAbove } from '../Styling/Queries';
import { ReactComponent as ArrowLeft } from '../../assets/svg/arrowLeft.svg';
import { rightandLeft } from '../Styling/Keyframes';
import { arrowColor } from '../Styling/Theme';

export default () => <StyledArrow className='arrow' />;

const StyledArrow = styled(ArrowLeft)`
    @media ${respondBelow.md} {
        right: 0;
        bottom: 0;
        display: block;
        height: 2rem;
        width: 3rem;
        margin-left: -0.3rem;
    }
    path {
        fill: ${arrowColor};
    }

    display: inline-block;
    height: 2rem;
    margin-left: -0.3rem;
    width: 3rem;
    @media ${respondAbove.md} {
        width: 60px;
        height: 30px;
        transform: scale(2);
        margin-left: 0;
    }
    &:hover {
        animation: ${rightandLeft()} 500ms ease alternate infinite;
    }
`;
