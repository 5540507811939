import React from 'react';

import styled from 'styled-components';

import Arrow from './Arrow';
import { rightandLeft } from '../Styling/Keyframes';
import { respondAbove, Mobile, Desktop } from '../Styling/Queries';
import { COLORS } from '../Data/VariablesData';
import { borderColor, educationTitleColor } from '../Styling/Theme';

// import { respondBelow, respondAbove } from '../Styling/Queries';

export default ({ title, period, name, link }) => {
    return (
        <Education key={title}>
            <EducationPeriod>{period}</EducationPeriod>
            <EducationTitle>{title}</EducationTitle>
            <EducationName>
                <a rel='noopener noreferrer' target='_blank' href={link}>
                    {name}
                    <Mobile>
                        <Arrow />
                    </Mobile>
                </a>
                <Desktop>
                    <Arrow />
                </Desktop>
            </EducationName>
        </Education>
    );
};

const Education = styled.div`
    border-left: 5px solid ${borderColor};
    font-size: 1.5rem;
    margin-bottom: 2rem;
    min-width: 30%;
    padding-left: 50px;
    padding-right: 10px;
    position: relative;

    @media ${respondAbove.md} {
        margin-bottom: 0;
        max-width: 45%;
        font-size: 3rem;
    }
`;
const EducationPeriod = styled.div`
    .edu-period {
        color: ${COLORS};
        font-weight: 700;
    }
`;
const EducationTitle = styled.div`
    color: ${educationTitleColor};
    font-size: 1rem;
    text-transform: uppercase;
    white-space: pre-wrap;
    @media ${respondAbove.sm} {
        font-size: 2.5rem;
    }
`;
const EducationName = styled.div`
    cursor: pointer;
    position: relative;
    a {
        color: white;
        font-size: 1.2rem;
        position: relative;
        text-decoration: none;

        @media ${respondAbove.sm} {
            font-size: 2.5rem;
        }
        .arrow {
            background-position: center;
            background-repeat: no-repeat;
            display: inline-block;
            height: 50px;
            position: absolute;
            top: 1rem;
            left: 0rem;
            width: 3rem;

            @media ${respondAbove.sm} {
                width: 65px;
                height: 65px;
            }
            @media ${respondAbove.md} {
                width: 90px;
                height: 90px;
            }
        }
    }
    &:hover {
        .arrow {
            animation: ${rightandLeft()} 500ms ease alternate infinite;
        }
    }
`;
