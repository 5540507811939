import React from 'react';
import { connect } from 'react-redux';
import { Tween } from 'react-gsap';
import { Controller, Scene } from 'react-scrollmagic';
import styled from 'styled-components';

import { MySkills } from '../Data/SkillsData';
import { KNOWLEDGEABLE_ID, LANGUES_ID, COLORS } from '../Data/VariablesData';
import { respondAbove } from '../Styling/Queries';
import { skillsLevelColor, borderColor } from '../Styling/Theme';

const SkillCategoryLevels = ({ isMobile, levelId, selectedCategory, turnOffScrollAnimation }) => {
    const strong = LANGUES_ID === selectedCategory ? 'Fluent' : 'Strong';
    const knowing = LANGUES_ID === selectedCategory ? 'Basic' : 'Knowledgeable';
    const levelLabel = levelId === KNOWLEDGEABLE_ID ? knowing : strong;
    return (
        <Category>
            <Controller>
                <Scene duration={turnOffScrollAnimation ? 0 : 500} offset={-700}>
                    <Tween from={{ x: isMobile ? '-100%' : '100%' }}>
                        <div key={levelLabel}>{selectedCategory && <Label>{levelLabel}</Label>}</div>
                    </Tween>
                </Scene>
                <Scene duration={turnOffScrollAnimation ? 0 : 500} offset={-700}>
                    <Tween
                        staggerFrom={{
                            opacity: 0,
                            cycle: {
                                x: i => `${isMobile ? '-' : ''}${100 + i * 10}%`,
                            },
                        }}
                        stagger={0.2}>
                        {MySkills.filter(
                            skill => skill.skillCategoryId === selectedCategory && skill.levelId === levelId
                        ).map(({ name }) => {
                            return <Skill key={name}>{name}</Skill>;
                        })}
                    </Tween>
                </Scene>
            </Controller>
        </Category>
    );
};
const mapStateToProps = ({ isMobile }) => ({ isMobile });

export default connect(mapStateToProps)(SkillCategoryLevels);

const Category = styled.div`
    margin-bottom: 0.5rem;
    min-width: 50%;
    transition: height 1s ease;

    &.collapse {
        max-height: 0;
        overflow: hidden;
    }
`;
const Label = styled.div`
    color: ${skillsLevelColor};
    font-size: 1.2rem;
    font-weight: normal;
    position: relative;
    text-transform: uppercase;
    @media ${respondAbove.md} {
        font-size: 2.5rem;
    }
`;
const Skill = styled.div`
    color: ${COLORS.WHITE};
    display: block;
    font-size: 1rem;
    font-weight: 400;
    margin: 10px 0;
    overflow: hidden;
    padding-left: 10px;
    position: relative;
    text-transform: initial;
    &:before {
        background-color: ${borderColor};
        content: ' ';
        height: 80%;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
    }

    @media ${respondAbove.md} {
        font-size: 2.3rem;
        padding-left: 30px;
    }
`;
