import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Scene, Controller } from 'react-scrollmagic';
import { Tween } from 'react-gsap';

import AppPages from '../components/AppPages';
import SkillCategory from '../components/SkillCategory';
import SkillCategoryMobile from '../components/SkillCategoryMobile';
import SkillCategoryLevels from '../components/SkillCategoryLevels';
import { STRONG_ID, KNOWLEDGEABLE_ID } from '../Data/VariablesData';
import { respondBelow, respondAbove } from '../Styling/Queries';

const SkillsSection = ({ rightAlign, isMobile }) => {
    const [selectedCategory, showCategory] = useState(1);

    return (
        <AppPages pageClass='skills' rightAlign={rightAlign} title='Skills'>
            <SkillsContainer>
                {isMobile ? (
                    <SkillCategoryMobile showCategory={showCategory} selectedCategory={selectedCategory} />
                ) : (
                    <SkillCategory showCategory={showCategory} selectedCategory={selectedCategory} />
                )}
                {isMobile ? null : (
                    <Controller>
                        <Scene duration={200}>
                            <Tween from={{ y: isMobile ? '100%' : 0 }}>
                                <div>
                                    <Skills>
                                        <SkillCategoryLevels levelId={STRONG_ID} selectedCategory={selectedCategory} />
                                        <SkillCategoryLevels
                                            levelId={KNOWLEDGEABLE_ID}
                                            selectedCategory={selectedCategory}
                                        />
                                    </Skills>
                                </div>
                            </Tween>
                        </Scene>
                    </Controller>
                )}
            </SkillsContainer>
        </AppPages>
    );
};

const mapStateToProps = ({ isMobile }) => ({ isMobile });

export default connect(mapStateToProps)(SkillsSection);

const SkillsContainer = styled.div`
    font-size: 1rem;
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
    width: 100%;

    @media ${respondAbove.md} {
        display: flex;
        justify-content: space-between;
        > div {
            flex: 1;
        }
    }
`;
const Skills = styled.div`
    @media ${respondBelow.md} {
        display: none;
    }

    @media ${respondAbove.md} {
        padding: 50px 0 0 20px;
        flex: 1;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
    }
`;
