import React, { Component } from 'react';
import { connect } from 'react-redux';

import '../../scss/Work.scss';
import JobModal from '../modals/JobModal';
import { jobs } from '../Data';
import AppPages from '../components/AppPages';
import WorkRoles from '../components/WorkRoles';
import WorkJobs from '../components/WorkJobs';

class WorkExperience extends Component {
    state = {
        jobrole: 1,
        open: false,
        data: jobs[1].data || [],
        companyName: 'Dwarf',
    };

    handleSelectRole = jobrole => {
        this.setState({ jobrole });
    };

    handleCloseModal = () => {
        this.setState({ open: false });
    };

    handleOpenJob = (data, companyName) => {
        this.setState({ open: true, data, companyName });
    };

    render() {
        const { jobrole, data, open, companyName } = this.state;
        const { isMobile } = this.props;
        return (
            <AppPages pageClass='work' title={'Work\nExperience'}>
                <div className='jobs' id='trigger'>
                    <JobModal open={open} closeAction={() => this.handleCloseModal()} data={data} name={companyName} />
                    {!isMobile && <WorkRoles handleSelectRole={this.handleSelectRole} jobrole={jobrole} />}
                    <WorkJobs isMobile={isMobile} jobrole={jobrole} handleOpenJob={this.handleOpenJob} />
                </div>
            </AppPages>
        );
    }
}

const mapStateToProps = ({ isMobile }) => ({ isMobile });

export default connect(mapStateToProps)(WorkExperience);
