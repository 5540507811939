import React from 'react';
import styled from 'styled-components';
// import { respondBelow, respondAbove } from '../Styling/Queries';

export default ({ reverse, children, props }) => (
    <Row {...props} reverse={reverse}>
        {children}
    </Row>
);

const Row = styled.div`
    display: flex;
    flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
    width: 100%;
`;
