import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { TimelineLite } from 'gsap/all';
import AppPages, { SpaceHeightBig, SpaceHeightMedium, SpaceHeightSmall } from '../components/AppPages';
import { respondAbove, respondBelow } from '../Styling/Queries';
import Row from '../components/Row';
import { flexUnit } from '../Styling/Mixins';
import PageTitle from '../components/PageTitle';
import SpaceXL from '../components/SpaceXL';
import ScrollIcon from '../components/ScrollIcon';
import { rightandLeft } from '../Styling/Keyframes';
import { textColor, contactHoverColor, outlineHeaderTheme, renderSwitch } from '../Styling/Theme';
import Arrow from '../components/Arrow';
import Menu from '../components/Menu';
import { ThemeToggleContext } from '../../ThemeContext';

class Introduction extends Component {
    static contextType = ThemeToggleContext;

    myElement;

    nameContainer;

    contactContainer;

    splitLetters = [];

    title;

    scroll;

    description;

    tl;

    state = {
        showScroll: false,
        menuOpen: false,
    };

    componentDidMount() {
        const { mode, isMobile } = this.props;

        const theme = renderSwitch(mode);

        const { showThemeSelector } = this.context;

        this.tl = isMobile
            ? this.MobileTimeline(theme, showThemeSelector)
            : this.DesktopTimeline(theme, showThemeSelector);
    }

    toggleMenu = (open) => {
        const { menuOpen } = this.state;
        this.setState({ menuOpen: open || !menuOpen });
    };

    playTimeline = () => {
        console.log('object');
    };

    DesktopTimeline = (theme) => {
        return new TimelineLite()
            .from(this.nameContainer, 0.5, {
                opacity: 0,
                fontSize: '8rem',
            })
            .to(this.nameContainer, 1, {
                fontSize: '8rem',
                opacity: 1,
            })
            .staggerTo(this.splitLetters, 0.25, {
                stagger: 0.1,
                color: theme,
                textShadow: 'none',
            })
            .to(this.splitLetters, 0.5, {
                stagger: 0,
            })
            .to(this.nameContainer, 0.75, {
                top: 0,
                opacity: 0,
                transform: 'translate(-50%, 0%)',
            })
            .to(this.nameContainer, 0.1, {
                left: '-50%',
                fontSize: '4rem',
                transform: 'translate(0%, 0%)',
            })
            .to(this.nameContainer, 0.75, {
                left: 0,
                opacity: 1,
                transform: 'translate(0%, 0%)',
            })
            .from(
                this.contactContainer,
                1,
                {
                    y: '-100vh',
                },
                3.3
            )
            .from(
                this.title,
                1.25,
                {
                    x: '-100vw',
                },
                3.3
            )
            .from(
                this.description,
                1.75,
                {
                    x: '100vw',
                },
                3.3
            );
    };

    MobileTimeline = (theme) => {
        return new TimelineLite()
            .from(this.nameContainer, 0.5, {
                opacity: 0,
                fontSize: '3.8rem',
            })
            .to(this.nameContainer, 1, {
                fontSize: '3.8rem',
                opacity: 1,
            })
            .staggerTo(this.splitLetters, 0.5, {
                stagger: 0.1,
                color: theme,
                textShadow: 'none',
            })
            .to(this.nameContainer, 1, {
                top: 0,
                transform: 'translate(-50%, 0%)',
            })
            .to(this.nameContainer, 0.5, {
                scale: 0.8,
                transformOrigin: 'center 0',
            })
            .from(
                this.contactContainer,
                1,
                {
                    x: '-100vw',
                },
                3.65
            )
            .from(
                this.title,
                1.2,
                {
                    x: '-100vw',
                },
                4.55
            )
            .from(
                this.description,
                1.2,
                {
                    x: '100vw',
                },
                4.55
            );
    };

    render() {
        const { rightAlign, isMobile } = this.props;
        const { showScroll, menuOpen } = this.state;
        const nameArray = [...'Haci'];
        const lastNameArray = [...'Kale'];
        return (
            <AppPages pageClass='intro' id='intro' rightAlign={rightAlign}>
                <Name
                    isMobile={isMobile}
                    className='name'
                    ref={(el) => {
                        this.nameContainer = el;
                    }}
                    splitLetters={this.splitLetters}>
                    {nameArray.map((item) => (
                        <Letter key={`haci${item}`} ref={(el) => this.splitLetters.push(el)}>
                            {item}
                        </Letter>
                    ))}
                    <Space />
                    {lastNameArray.map((item) => (
                        <Letter key={`kale${item}`} ref={(el) => this.splitLetters.push(el)}>
                            {item}
                        </Letter>
                    ))}
                </Name>

                <Contact
                    ref={(el) => {
                        this.contactContainer = el;
                    }}>
                    <Anchor onClick={() => this.toggleMenu()}>
                        <div> {menuOpen ? 'CLOSE' : 'MENU'}</div>
                        <Arrow />
                    </Anchor>
                    <Menu menuOpen={menuOpen} />
                </Contact>

                {isMobile ? <SpaceHeightBig /> : <SpaceHeightMedium />}
                <Row reverse={rightAlign}>
                    <SpaceXL />
                    <PageTitle
                        ref={(el) => {
                            this.title = el;
                        }}
                        title={'Front-end\nDeveloper'}
                    />
                </Row>
                {isMobile ? <SpaceHeightSmall /> : <SpaceHeightMedium />}
                <Row>
                    <Description
                        ref={(el) => {
                            this.description = el;
                        }}
                        dangerouslySetInnerHTML={{
                            __html: '“Hi, I create responsive websites using\nReactJs, Javascript and Sass.”',
                        }}
                    />
                </Row>

                <ScrollIcon inProp={showScroll} />
            </AppPages>
        );
    }
}

const Description = styled.div`
    /* font-size: 1.5rem; */
    color: ${textColor};
    font-weight: 700;
    line-height: 1.5;
    margin: 0;
    margin-left: 4rem;
    text-transform: uppercase;
    white-space: pre-wrap;
    ${flexUnit(5, 20, 40)};

    @media ${respondAbove.md} {
        /* font-size: 2.3rem; */
        margin-left: 20rem;
        padding: 0;
    }
`;

const Letter = styled.div`
    font-family: 'IBM Plex Sans Condensed';
    font-weight: bold;
    ${outlineHeaderTheme}
`;

const Space = styled.div`
    width: 0.5rem;
    @media ${respondAbove.md} {
        width: 0.85rem;
    }
`;

const Name = styled.div`
    align-items: center;
    display: flex;
    font-size: ${(props) => (props.isMobile ? '4rem' : '6rem')};
    justify-content: center;
    left: 50%;
    margin: 0 auto;
    opacity: 0;
    padding: 1.2rem 2rem;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    transform: translate(-50%, -50%);
`;
const Contact = styled.div`
    cursor: pointer;

    font-size: 1.5rem;
    font-weight: bold;
    margin-left: auto;
    padding-top: 1.2rem;
    position: relative;
    text-transform: uppercase;
    width: fit-content;
    z-index: 1;
    @media ${respondBelow.md} {
        position: absolute;
        top: 3rem;
        right: -1rem;
    }
    @media ${respondAbove.md} {
        margin-right: 50px;
        text-align: end;
        font-size: 3rem;
    }
`;
const Anchor = styled.div`
    ${outlineHeaderTheme};
    display: flex;
    &:hover {
        color: ${contactHoverColor};
        text-shadow: none;
        .arrow {
            animation: ${rightandLeft(1.5)} 500ms ease alternate infinite;
        }
    }

    .arrow {
        display: inline-block;
        height: 40px;
        position: relative;
        top: 0px;
        transform: scale(1.5);
        width: 50px;
        @media ${respondAbove.md} {
            height: 3rem;
            position: relative;
            margin-left: 1rem;
        }
    }
`;
const mapStateToProps = ({ isMobile }) => ({ isMobile });

export default connect(mapStateToProps)(Introduction);
