import { CLEAR_APP_STATE, MEDIA_CHANGE } from '../actions/actionTypes';

const initialState = {
    isMobile: false,
    mediaChecked: false,
};

const mainReducer = (state = initialState, { type, payload = {} }) => {
    switch (type) {
        case MEDIA_CHANGE: {
            return {
                ...state,
                isMobile: !payload.mediaQuery.matches,
                mediaChecked: true,
            };
        }
        case CLEAR_APP_STATE:
            return initialState;
        default:
            return state;
    }
};

export default mainReducer;
