import React from 'react';
import { Tween } from 'react-gsap';
import { Controller, Scene } from 'react-scrollmagic';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import ScrollSvg from '../../assets/svg/scroll.svg';
import { respondAbove } from '../Styling/Queries';
import { downandup } from '../Styling/Keyframes';

export default ({ inProp }) => {
    const transitionStyles = {
        entering: { opacity: 0 },
        entered: { opacity: 1 },
        exiting: { opacity: 0 },
        exited: { opacity: 0 },
    };
    return (
        <Transition in={inProp} timeout={200} duraion={300}>
            {state => (
                <div style={{ ...{ transform: 'opacity 2s ease' }, ...transitionStyles[state] }}>
                    <Controller>
                        <Scene duration={200} offset={0}>
                            <Tween to={{ opacity: 0 }}>
                                <div>
                                    <ScrollIcon state={state}>
                                        <img src={ScrollSvg} alt='scroll' />
                                    </ScrollIcon>
                                </div>
                            </Tween>
                        </Scene>
                    </Controller>
                </div>
            )}
        </Transition>
    );
};

const ScrollIcon = styled.div`
    bottom: 4rem;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    width: 100%;
    @media ${respondAbove.md} {
        bottom: 1rem;
    }
    img {
        animation: ${downandup} 1000ms 7s ease alternate infinite;
        animation-play-state :${props => (props.state === 'entered' ? 'running' : 'paused')}
        margin: auto;
        width: 40px;
    }
`;
