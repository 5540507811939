import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { backgroundColor, textColor } from './js/Styling/Theme';

export const ThemeToggleContext = React.createContext();
export const useTheme = () => React.useContext(ThemeToggleContext);
export const ThemeToggleConsumer = ThemeToggleContext.Consumer;
export const ThemeToggleProvider = ThemeToggleContext.Provider;

export const themes = ['dark', 'violet', 'purple', 'green', 'blue', 'mcd'];

export const MyThemeProvider = ({ children }) => {
    const [themeState, setThemeState] = useState({
        mode: 'dark',
        showThemeSelector: true,
    });
    useEffect(() => {
        const mode = sessionStorage.getItem('mode');

        if (mode) {
            setThemeState({ mode: mode, showThemeSelector: true });
        }
    }, []);

    const Wrapper = styled.div`
        background-color: ${backgroundColor};
        color: ${textColor};
        transition: all 1s ease;
    `;

    const toggle = mode => {
        sessionStorage.setItem('mode', mode);

        setThemeState({ mode: mode, showThemeSelector: false });
    };
    const toggleShowThemeSelector = show => {
        setThemeState({ showThemeSelector: show });
    };

    return (
        <ThemeToggleProvider
            value={{
                toggle: toggle,
                mode: themeState.mode,
                showThemeSelector: themeState.showThemeSelector,
                toggleShowThemeSelector: toggleShowThemeSelector,
            }}>
            <ThemeProvider
                theme={{
                    mode: themeState.mode,
                }}>
                <Wrapper>{children}</Wrapper>
            </ThemeProvider>
        </ThemeToggleProvider>
    );
};

export default ThemeProvider;
