import React from 'react';
import styled from 'styled-components';

import { respond } from '../../Styling/Queries';
import { uuidv4 } from '../../utils';
import { complementColor, textColor } from '../../Styling/Theme';

export default ({ data, title }) => (
    <JobTools>
        <JobToolsLabel>{title}</JobToolsLabel>
        <JobToolsLevel>
            {data.map(desc => (
                <JobToolsText key={uuidv4()} dangerouslySetInnerHTML={{ __html: desc.name }} />
            ))}
        </JobToolsLevel>
    </JobTools>
);
const JobTools = styled.div`
    color: ${textColor};
    font-size: 1rem;
    margin: 3rem auto;
`;
const JobToolsLabel = styled.div`
    color: ${complementColor};
    display: block;
    font-size: 2rem;
    text-transform: uppercase;
    @media screen and ${respond.above.md} {
        font-size: 3rem;
    }
`;
const JobToolsLevel = styled.div`
    display: flex;
    flex-wrap: wrap;
    @media screen and ${respond.above.md} {
        max-height: 300px;
        flex-direction: column;
    }
`;
const JobToolsText = styled.div`
    margin: 5px;
    @media screen and ${respond.above.md} {
        padding: 10px 0;
        font-size: 2rem;
    }
`;
