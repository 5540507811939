import { CLEAR_APP_STATE, MEDIA_CHANGE, EDIT_CASE, DELETE_CASE, ADD_CASE } from './actionTypes';

export const clearState = () => ({
    type: CLEAR_APP_STATE,
});

export const mediaChange = mediaQuery => ({
    type: MEDIA_CHANGE,
    payload: { mediaQuery },
});
export const addCase = product => ({
    type: ADD_CASE,
    payload: { product },
});
export const editCase = (product, mode) => ({
    type: EDIT_CASE,
    payload: { product, mode },
});
export const deleteCase = product => ({
    type: DELETE_CASE,
    payload: { product },
});
