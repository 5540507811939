import React from 'react';
import styled from 'styled-components';
import { respondAbove } from '../../Styling/Queries';

export default ({ children }) => <Info>{children}</Info>;

const Info = styled.div`
    cursor: pointer;
    position: relative;
    text-transform: uppercase;
    @media screen and ${respondAbove.md} {
        font-weight: bold;
        font-size: 2rem;
    }
    .arrow {
        display: inline-block;
        height: 50px;
        position: absolute;
        top: 0px;
        width: 50px;
    }
`;
