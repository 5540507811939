import React from 'react';
import { connect } from 'react-redux';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';

import { jobs } from '../Data';
import CustomH1 from './CustomH1';

const WorkJobs = ({ isMobile, jobrole, handleOpenJob }) => (
    <Controller>
        <Scene duration={600} offset={isMobile ? -400 : -750}>
            <Tween
                staggerFrom={{
                    opacity: 0,
                    cycle: {
                        x: i => `${100 + i * 10}%`,
                    },
                }}
                stagger={0.2}>
                {jobs
                    .filter(({ roleId }) => (isMobile ? true : roleId === jobrole))
                    .map(({ name, data }) => (
                        <div
                            role='button'
                            tabIndex={0}
                            key={name}
                            onClick={() => handleOpenJob(data, name)}
                            onKeyPress={({ key }) => key === 'Enter' && handleOpenJob(data, name)}>
                            <CustomH1 arrow id={`${name}`}>
                                <span>{name}</span>
                            </CustomH1>
                        </div>
                    ))}
            </Tween>
        </Scene>
    </Controller>
);

const mapStateToProps = ({ isMobile }) => ({ isMobile });

export default connect(mapStateToProps)(WorkJobs);
