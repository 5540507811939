import { useMediaQuery } from 'react-responsive';

export const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
};
export const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    return isTablet ? children : null;
};
// Original
// export const Mobile = ({ children }) => {
//     const isMobile = useMediaQuery({ maxWidth: 767 });
//     return isMobile ? children : null;
// };
export const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 991 });
    return isMobile ? children : null;
};
export const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 });
    return isNotMobile ? children : null;
};

const breakpoints = {
    xs: '576px',
    sm: '768px',
    md: '992px',
    lg: '1200px',
};

export const respondAbove = {};
export const respondBelow = {};
export const respond = { above: {}, below: {} };

Object.keys(breakpoints).forEach(item => {
    respondAbove[item] = `(min-width: ${breakpoints[item]})`;
    respond.above[item] = `(min-width: ${breakpoints[item]})`;
});
Object.keys(breakpoints).forEach(item => {
    respondBelow[item] = `(max-width: ${breakpoints[item]})`;
    respond.below[item] = `(min-width: ${breakpoints[item]})`;
});

// export const device = {
//     mobileS: `(min-width: ${

//     })`,
//     mobileM: `(min-width: ${

//     })`,
//     mobileL: `(min-width: ${

//     })`,
//     tablet: `(min-width: ${

//     })`,
//   };
